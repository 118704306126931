* {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6, p, a, li, i, {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-add {
  width: 18rem;
  display: flex;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  cursor: pointer;
}

.card-add:hover {
  box-shadow: 3px 2px 46px -11px #f9f9f9;
}

.card-add p {
  font-size: 55px;
}

.card-clickable {
  cursor: pointer;
}

.card-clickable:hover {
  box-shadow: 3px 2px 46px -11px #c3c3c3;
}

.w70 {
  width: 70%;
}

.tblack {
  color: black !important;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #f9f9f9;
}

.editor-class {
  background-color: #f9f9f9;
  padding: 1rem;
  border: 1px solid #f9f9f9;
}

.toolbar-class {
  border: 1px solid #f9f9f9;
}

.ql-container {
    font-size: 35px !important;
}
